
// Browser Messaging

import {OutgoingMessage} from "./slice/chatFeedSlice";
import {ExtensionType} from "../util/extension.type";

export enum BrowserMessageEventType {
    CONVO_USER_RESP = 'CONVO_USER_RESP',
    GET_ORIGIN = 'GET_ORIGIN',
    HEALTH = 'HEALTH',
    ORIGIN_ACK = 'ORIGIN_ACK',
    RESIZE = 'RESIZE'
}

// Socket Connection

export enum ConnectionNameSpace {
    ROOT = 'ROOT',
    CONVERSATION = 'conversation',
    CONVO_EVENT = 'convo-event',
}

export enum ConnectionEvent{
    CONNECT_SUCCESS = 'CONNECT_SUCCESS',
    CONNECT_ERROR = 'CONNECT_ERROR',
    DISCONNECT = 'DISCONNECT'
}

export enum ConversationType {
    FAILED = 'FAILED',
    SUCCESS = 'SUCCESS'
}

export enum EmitEventResponseStatus {
    NOT_CONFIGURED = 'NOT_CONFIGURED',
    ERROR = 'ERROR',
    UNAUTHORIZED = 'UNAUTHORIZED',
    SUCCESS = 'SUCCESS'
}


export enum ConvoEventType{
    CHAT_EVENT = 'CHAT_EVENT',
}

export enum ChatEventType{
    CHAT_LOADED = 'CHAT_LOADED'
}

export enum ConvoEventResponseType{
    CHAT_RESPONSE = 'CHAT_RESPONSE',
    NO_OP_RESPONSE = 'NO_OP_RESPONSE'
}



export type ConvoEventDataType = {
    type: ConvoEventType
    eventId: string
    instanceId: string
}

export type ChatConvoEventType = {
    type: ConvoEventType.CHAT_EVENT,
    chatEventType: ChatEventType,
} & ConvoEventDataType


export type ChatLoadedConvoEvent = {
    botId: string
    versionId: string
    channel: ExtensionType
    chatEventType: ChatEventType.CHAT_LOADED
    referrer?: string
} & ChatConvoEventType


export type ChatConvoEvent = ChatLoadedConvoEvent

export type ConvoEventData = ChatConvoEvent

export type ConversationEvent = {
    namespace: ConnectionNameSpace.CONVERSATION,
    event: OutgoingMessage
}

export type ConvoEvent = {
    namespace: ConnectionNameSpace.CONVO_EVENT,
    event: ChatLoadedConvoEvent
}

export type EmitEvent = ConversationEvent | ConvoEvent


export type EmitEventResponse = {
    status: EmitEventResponseStatus
}


//