import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { configStore } from "./app/store";
import App from "./App";
import { createTheme } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import {validUrlOrNone} from "./util/helper";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//get params from url
const url = new URL(window.location.href);
const encodedTheme = url.searchParams.get("theme");
const urlPath = url.searchParams.get("urlPath");
// Decode the base64 string and then parse the JSON
let theme;
if (encodedTheme) {
  try {
    const decodedString = decodeURIComponent(encodedTheme);
    const themeParams = JSON.parse(decodedString);
    theme = createTheme(themeParams);
  } catch (error) {
    theme = createTheme();
  }
} else {
  theme = createTheme();
}

const decodedUrlPath = decodeURIComponent(urlPath!)

const validatedUrl = validUrlOrNone(decodedUrlPath)

if(validatedUrl) {
  root.render(
      <React.StrictMode>
        <Provider store={configStore()}>
          <App urlPath={decodedUrlPath} theme={theme}/>
        </Provider>
      </React.StrictMode>
  );
}else {
  console.error(`Invalid url ${urlPath}`)
  root.render(<></>)
}
